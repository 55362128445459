:global {
  .calculation-tool-box {
    // height: 44px;
    .delivery-date-inline {
      .ant-form-item {
        margin-bottom: 0 !important;
      }
      .ant-form-item-control-input,
      form,
      .ant-form-item {
        height: 100%;
      }
    }
  }

  .sticky-outer-wrapper .calculation-tool-box {
    padding: 0;
    transition: padding 0.2s ease-in-out;
  }

  .sticky-outer-wrapper.active {
    .calculation-tool-box {
      // position: relative;
      padding: 0.8rem 0;
      margin: 0 !important;

      &::before {
        content: "";
        position: fixed;
        top: 0;
        right: -1.5rem;
        width: 100vw;
        height: 100%;
        background: white;
        box-shadow: 3px 3px 10px 2px rgba(0, 0, 0, 0.4);
      }
    }
  }
}
